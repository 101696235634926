import { isZodType, isAppRoute, isZodObject, extractZodObjectShape } from '@ts-rest/core';
import { generateSchema } from '@anatine/zod-openapi';
const getPathsFromRouter = (router, pathHistory) => {
  const paths = [];
  Object.keys(router).forEach(key => {
    const value = router[key];
    if (isAppRoute(value)) {
      const pathWithPathParams = value.path.replace(/:(\w+)/g, '{$1}');
      paths.push({
        id: key,
        path: pathWithPathParams,
        route: value,
        paths: pathHistory !== null && pathHistory !== void 0 ? pathHistory : []
      });
    } else {
      paths.push(...getPathsFromRouter(value, [...(pathHistory !== null && pathHistory !== void 0 ? pathHistory : []), key]));
    }
  });
  return paths;
};
const getOpenApiSchemaFromZod = (zodType, useOutput = false) => {
  if (!isZodType(zodType)) {
    return null;
  }
  return generateSchema(zodType, useOutput);
};
const getPathParameters = (path, zodObject) => {
  var _a;
  const isZodObj = isZodObject(zodObject);
  const zodShape = isZodObj ? extractZodObjectShape(zodObject) : {};
  const paramsFromPath = (_a = path.match(/{[^}]+}/g)) === null || _a === void 0 ? void 0 : _a.map(param => param.slice(1, -1)).filter(param => {
    return zodShape[param] === undefined;
  });
  const params = (paramsFromPath === null || paramsFromPath === void 0 ? void 0 : paramsFromPath.map(param => ({
    name: param,
    in: 'path',
    required: true,
    schema: {
      type: 'string'
    }
  }))) || [];
  if (isZodObj) {
    const paramsFromZod = Object.entries(zodShape).map(([key, value]) => {
      const {
        description,
        ...schema
      } = getOpenApiSchemaFromZod(value);
      return {
        name: key,
        in: 'path',
        required: true,
        schema,
        ...(description && {
          description
        })
      };
    });
    params.push(...paramsFromZod);
  }
  return params;
};
const getHeaderParameters = zodObject => {
  const isZodObj = isZodObject(zodObject);
  if (!isZodObj) {
    return [];
  }
  const zodShape = extractZodObjectShape(zodObject);
  return Object.entries(zodShape).map(([key, value]) => {
    const schema = getOpenApiSchemaFromZod(value);
    const isRequired = !value.isOptional();
    return {
      name: key,
      in: 'header',
      ...(isRequired && {
        required: true
      }),
      ...{
        schema: schema
      }
    };
  });
};
const getQueryParametersFromZod = (zodObject, jsonQuery = false) => {
  const isZodObj = isZodObject(zodObject);
  if (!isZodObj) {
    return [];
  }
  const zodShape = extractZodObjectShape(zodObject);
  return Object.entries(zodShape).map(([key, value]) => {
    const {
      description,
      mediaExamples: examples,
      ...schema
    } = getOpenApiSchemaFromZod(value);
    const isObject = obj => {
      while (obj._def.innerType) {
        obj = obj._def.innerType;
      }
      return obj._def.typeName === 'ZodObject';
    };
    const isRequired = !value.isOptional();
    return {
      name: key,
      in: 'query',
      ...(description && {
        description
      }),
      ...(isRequired && {
        required: true
      }),
      ...(jsonQuery ? {
        content: {
          'application/json': {
            schema: schema,
            ...(examples && {
              examples
            })
          }
        }
      } : {
        ...(isObject(value) && {
          style: 'deepObject'
        }),
        schema: schema
      })
    };
  });
};
const convertSchemaObjectToMediaTypeObject = input => {
  const {
    mediaExamples: examples,
    ...schema
  } = input;
  return {
    schema,
    ...(examples && {
      examples
    })
  };
};
const generateOpenApi = (router, apiDoc, options = {}) => {
  const paths = getPathsFromRouter(router);
  const mapMethod = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
    PATCH: 'patch'
  };
  const operationIds = new Map();
  const pathObject = paths.reduce((acc, path) => {
    var _a, _b, _c, _d;
    if (options.setOperationId === true) {
      const existingOp = operationIds.get(path.id);
      if (existingOp) {
        throw new Error(`Route '${path.id}' already defined under ${existingOp.join('.')}`);
      }
      operationIds.set(path.id, path.paths);
    }
    const pathParams = getPathParameters(path.path, path.route.pathParams);
    const headerParams = getHeaderParameters(path.route.headers);
    const querySchema = getQueryParametersFromZod(path.route.query, !!options.jsonQuery);
    const bodySchema = ((_a = path.route) === null || _a === void 0 ? void 0 : _a.method) !== 'GET' ? getOpenApiSchemaFromZod(path.route.body) : null;
    const responses = Object.entries(path.route.responses).reduce((acc, [statusCode, response]) => {
      const responseSchema = getOpenApiSchemaFromZod(response, true);
      const description = isZodType(response) && response.description ? response.description : statusCode;
      return {
        ...acc,
        [statusCode]: {
          description,
          ...(responseSchema ? {
            content: {
              'application/json': {
                ...convertSchemaObjectToMediaTypeObject(responseSchema)
              }
            }
          } : {})
        }
      };
    }, {});
    const contentType = ((_b = path.route) === null || _b === void 0 ? void 0 : _b.method) !== 'GET' ? (_d = (_c = path.route) === null || _c === void 0 ? void 0 : _c.contentType) !== null && _d !== void 0 ? _d : 'application/json' : 'application/json';
    const pathOperation = {
      description: path.route.description,
      summary: path.route.summary,
      deprecated: path.route.deprecated,
      tags: path.paths,
      parameters: [...pathParams, ...headerParams, ...querySchema],
      ...(options.setOperationId ? {
        operationId: options.setOperationId === 'concatenated-path' ? [...path.paths, path.id].join('.') : path.id
      } : {}),
      ...(bodySchema ? {
        requestBody: {
          description: 'Body',
          content: {
            [contentType]: {
              ...convertSchemaObjectToMediaTypeObject(bodySchema)
            }
          }
        }
      } : {}),
      responses
    };
    acc[path.path] = {
      ...acc[path.path],
      [mapMethod[path.route.method]]: options.operationMapper ? options.operationMapper(pathOperation, path.route) : pathOperation
    };
    return acc;
  }, {});
  return {
    openapi: '3.0.2',
    paths: pathObject,
    ...apiDoc
  };
};
export { generateOpenApi };