import { Component, Input } from "@angular/core";
import { AnyTarget } from "src/app/pages/channels/channel";

@Component({
    selector: "app-zx-target-channel-column",
    templateUrl: "./zx-target-channel-column.component.html"
})
export class ZxTargetChannelColumnComponent {
    @Input() target: AnyTarget;
    @Input() searchTerm: string;
}
