<ng-template #columnSelection>
    <div class="form-group mb-0" *ngIf="isColumnSelectionNeeded && showColumnsSelection && !isCollapseMode && !childTable">
        <label class="sr-only">{{ "COLUMN_SELECTION" | translate }}</label>
        <div title="{{ 'COLUMN_SELECTION' | translate }}">
            <button
                class="btn btn-outline-secondary"
                id="columnSelectionDropdown"
                [matMenuTriggerFor]="colMenu"
                #colTrigger="matMenuTrigger"
                [tourAnchor]="showTour ? 'eigthTableAnchor' : ''"
            >
                <fa-icon icon="columns" size="md"></fa-icon><span class="d-none d-lg-inline-block optional-label ms-1">{{ "COLUMNS" | translate }}</span>
            </button>
            <div aria-labelledby="columnSelectionDropdown" class="shadow-sm">
                <mat-menu #colMenu="matMenu" yPosition="below" class="{{ countOfColumnsInChooser() > 14 ? 'twoCol' : '' }}">
                    <ng-container *ngFor="let column of tableSchema; trackBy: trackByFn">
                        <mat-checkbox
                            *ngIf="!(columnsToHideFromColumnChooser.includes(column.columnDef) || column.hideFromColumnChooser)"
                            class="text-secondary"
                            (click)="$event.stopPropagation()"
                            (change)="toggleColumn(column)"
                            [checked]="column.visible"
                            title="{{ column.header }}"
                        >
                            {{ column.header }}
                        </mat-checkbox>
                    </ng-container>
                    <div class="dropdown-divider"></div>
                    <div class="px-3 py-2 text-end">
                        <small [tourAnchor]="showTour ? 'ninthTableAnchor' : ''"
                            ><a href="javascript:void(0)" (click)="$event.stopPropagation(); resetColumnConfig()"
                                ><fa-icon icon="undo" class="me-1" size="xs"></fa-icon>{{ "RESET_COLUMN_CONFIGURATION" | translate }}</a
                            ></small
                        >
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #columnSelectionChildTable>
    <div title="{{ 'COLUMN_SELECTION' | translate }}">
        <button
            class="btn btn-sm btn-xs btn-outline-secondary border-0 bg-transparent"
            id="columnSelectionDropdown"
            [matMenuTriggerFor]="colMenu"
            #colTrigger="matMenuTrigger"
            (click)="$event.preventDefault(); $event.stopPropagation()"
        >
            <fa-icon icon="columns" size="md"></fa-icon>
        </button>
        <div aria-labelledby="columnSelectionDropdown" class="shadow-sm">
            <mat-menu #colMenu="matMenu" yPosition="below" class="{{ countOfColumnsInChooser() > 14 ? 'twoCol' : '' }}">
                <ng-container *ngFor="let column of tableSchema; trackBy: trackByFn">
                    <mat-checkbox
                        *ngIf="!(columnsToHideFromColumnChooser.includes(column.columnDef) || column.hideFromColumnChooser)"
                        class="text-secondary"
                        (click)="$event.stopPropagation()"
                        (change)="toggleColumn(column)"
                        [checked]="column.visible"
                        title="{{ column.header }}"
                    >
                        {{ column.header }}
                    </mat-checkbox>
                </ng-container>
                <div class="dropdown-divider"></div>
                <div class="px-3 py-2 text-end">
                    <small
                        ><a href="javascript:void(0)" (click)="$event.stopPropagation(); resetColumnConfig()"
                            ><fa-icon icon="undo" class="me-1" size="xs"></fa-icon>{{ "RESET_COLUMN_CONFIGURATION" | translate }}</a
                        ></small
                    >
                </div>
            </mat-menu>
        </div>
    </div>
</ng-template>

<app-table-material-wrapper>
    <div class="table-filter-container" #tableFilterContainer [ngClass]="{ 'mb-0': (!showColumnsSelection || childTable) && !showReport && !showFilter }">
        <div class="form-inline">
            <!-- Filtering Controls -->
            <app-filter
                class="form-inline flex-grow-1 flex-shrink-1 grid-gap-2 align-items-end"
                [ngClass]="{ 'ps-3 pe-3': inAccordion }"
                [showFilter]="showFilter"
                [showTour]="showTour"
                [filterName]="tableName"
                [collapsed]="isCollapseMode"
                [schema]="tableSchema"
                [(tableFilter)]="tableData.filter"
                [dataLength]="tableData.data.length"
                [filteredLength]="tableData.filteredData.length"
                [childTable]="childTable"
            >
                <!-- Bulk Actions -->
                <ng-content></ng-content>

                <!-- Column Selection -->
                <ng-container *ngTemplateOutlet="columnSelection"></ng-container>

                <!-- Report -->
                <div class="form-group mb-0" *ngIf="showReport && !isCollapseMode">
                    <button
                        type="button"
                        class="btn btn-outline-secondary"
                        title="{{ 'REPORT' | translate }}"
                        (click)="report()"
                        [tourAnchor]="showTour ? 'tenthTableAnchor' : ''"
                    >
                        <fa-icon icon="file-excel"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "REPORT" | translate }}</span>
                    </button>
                </div>
                <!-- Tour -->
                <div
                    class="form-group mb-0 d-flex align-items-center"
                    [ngStyle]="{
                        'height.px': 35
                    }"
                    *ngIf="showTour && !isCollapseMode"
                >
                    <fa-icon
                        class="tourStartButton"
                        icon="question-circle"
                        #tooltip="matTooltip"
                        matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_TABLE_FEATURES_BY_CLICKING_HERE' | translate }}"
                        matTooltipPosition="right"
                        matTooltipClass="left"
                        size="lg"
                        (click)="tourService.start(); mixpanelService.sendEvent('start table feature guide')"
                    ></fa-icon>
                </div>
            </app-filter>
        </div>
    </div>
    <div class="mat-mdc-table-container" id="table-container" #tableContainer>
        <table
            mat-table
            [dataSource]="tableData"
            matSort
            (matSortChange)="sortData($event)"
            cdkDropList
            cdkDropListGroup
            cdkDropListOrientation="horizontal"
            (cdkDropListDropped)="dropColumn($event)"
            (contentChanged)="createRefComponent()"
            [trackBy]="dataTrackByFn"
            [ngClass]="{ 'no-hover': !hoverable }"
            multiTemplateDataRows
        >
            <ng-container *ngFor="let column of tableSchema; index as i; trackBy: trackByFn" [matColumnDef]="column.columnDef" [sticky]="false">
                <!-- Expand Button Column -->
                <ng-container *ngIf="column.columnDef === 'expand'">
                    <th
                        scope="col"
                        class="expandbutton-cell"
                        mat-header-cell
                        *matHeaderCellDef
                        [appResizeColumn]="true"
                        [index]="i"
                        (click)="expandContractAllRows(); $event.stopPropagation()"
                    >
                        <fa-icon size="lg" icon="square-plus" *ngIf="noRowsExpanded"></fa-icon>
                        <fa-icon size="lg" icon="square-minus" *ngIf="!noRowsExpanded"></fa-icon>
                    </th>
                    <td
                        class="expandbutton-cell"
                        mat-cell
                        *matCellDef="let r"
                        (click)="!column.expandDisabled(r) && expandButtonClick(r); $event.stopPropagation()"
                    >
                        <fa-icon class="chevron-down" size="md" icon="chevron-down" *ngIf="!r.isExpanded && !column.expandDisabled(r)"></fa-icon>
                        <fa-icon class="chevron-up" size="md" icon="chevron-up" *ngIf="r.isExpanded && !column.expandDisabled(r)"></fa-icon>
                    </td>
                </ng-container>
                <!-- Checkbox Column -->
                <ng-container *ngIf="column.columnDef === 'select'">
                    <th scope="col" class="checkbox-cell" mat-header-cell *matHeaderCellDef [appResizeColumn]="true" [index]="i">
                        <div cdkDrag cdkDragLockAxis="x" [cdkDragDisabled]="true">
                            <mat-checkbox
                                (change)="masterToggle($event)"
                                [checked]="tableSelection.hasValue() && isAllSelected()"
                                [indeterminate]="tableSelection.hasValue() && !isAllSelected()"
                            >
                            </mat-checkbox>
                        </div>
                    </th>
                    <td class="checkbox-cell" mat-cell *matCellDef="let r" (click)="checkboxRowClick($event, r); $event.stopPropagation()">
                        <mat-checkbox (click)="$event.stopPropagation()" [checked]="tableSelection.isSelected(r)" (change)="checkboxRowClick($event, r)">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <!-- Others Columns -->
                <ng-container>
                    <!-- Standard Column Header -->
                    <th
                        #container
                        scope="col"
                        [style.width]="column.width + 'px'"
                        mat-header-cell
                        *matHeaderCellDef
                        (resize)="widthChanged(column.columnDef, $event)"
                        [def]="column.columnDef"
                        [appResizeColumn]="true"
                        [index]="i"
                        title="{{ column.header }}"
                        [tourAnchor]="getTourIDs(column)"
                        cdkDrag
                        cdkDragLockAxis="x"
                        [cdkDragDisabled]="column.sticky != null || column.stickyToLast"
                    >
                        <div cdkDragHandle mat-sort-header [disabled]="!column.sortBy">
                            {{ column.hideColumnName ? "" : column.header }}
                            <!-- Child/Sub Table Column Selection -->
                            <span *ngIf="column.columnDef === 'actions' && childTable">
                                <ng-container *ngTemplateOutlet="columnSelectionChildTable"></ng-container>
                            </span>
                            <fa-icon
                                *ngIf="!!column.tooltip"
                                icon="question-circle"
                                size="sm"
                                [matTooltip]="column.tooltip"
                                matTooltipPosition="above"
                                triggers="hover click"
                                [closeDelay]="500"
                            ></fa-icon>
                        </div>
                    </th>
                    <!-- Standard cell -->
                    <td
                        mat-cell
                        *matCellDef="let row"
                        [ngStyle]="{ 'text-align': column.align }"
                        [ngClass]="getCellBackground(row, column)"
                        [style]="getCellStyle(row, column)"
                    >
                        <ng-container
                            *ngIf="!!column.component && (!column.rowIf || column.rowIf(row))"
                            #tdInjectContainer
                            appContainerData
                            [appContainerData]="{column, row}"
                        ></ng-container>
                        <ng-container *ngIf="!column.component && (!column.rowIf || column.rowIf(row))">
                            <ngb-highlight
                                *ngIf="column.translate"
                                title="{{ row[column.columnDef].toUpperCase() | translate }}"
                                [result]="row[column.columnDef].toUpperCase() | translate"
                                [term]="searchTermArray"
                            ></ngb-highlight>
                            <ngb-highlight
                                *ngIf="!column.translate"
                                title="{{ row[column.columnDef] }}"
                                [result]="row[column.columnDef]"
                                [term]="searchTermArray"
                            ></ngb-highlight>
                        </ng-container>
                    </td>
                </ng-container>
            </ng-container>

            <!-- Expanded Content Column -->
            <ng-container matColumnDef="emptyCell">
                <td mat-cell *matCellDef="let row" class="empty-cell" [attr.colspan]="selectable && hasSelectCol() ? 2 : 1"></td>
            </ng-container>
            <ng-container matColumnDef="expandedRowDetail">
                <td mat-cell *matCellDef="let row" class="p-0" [attr.colspan]="userLastColumnConfig.length - (selectable && hasSelectCol() ? 2 : 1)">
                    <div class="expanded-row-content">
                        <ng-container *ngFor="let column of tableSchema; trackBy: trackByFn">
                            <!-- Use component of expand column -->
                            <ng-container
                                *ngIf="!!column.component && column.columnDef === 'expand'"
                                #tdInjectContainer
                                appContainerData
                                [appContainerData]="{column, row}"
                            ></ng-container>
                        </ng-container>
                    </div>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="userLastColumnConfig; sticky: tableHeaderSticky && !childTable"
                [tourAnchor]="showTour ? 'fourthTableAnchor' : ''"
            ></tr>

            <tr
                [ngClass]="{ selected: tableSelection.isSelected(row) }"
                mat-row
                *matRowDef="let row; columns: userLastColumnConfig"
                (click)="rowClick(row, $event)"
                [draggable]="dragRowHandler"
                (dragstart)="dragRowHandler && onDragRowStart(row, $event)"
            ></tr>

            <!-- Expand Row -->
            <ng-container *ngIf="showExpandButton">
                <tr
                    mat-row
                    *matRowDef="let row; columns: ['emptyCell', 'expandedRowDetail']"
                    class="expanded-row"
                    [ngClass]="{ 'row-collapsed': !row.isExpanded, 'row-expanded': row.isExpanded }"
                ></tr>
            </ng-container>

            <!-- No results -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="userLastColumnConfig.length" *ngIf="tableData.data.length && !refreshing">
                    {{ "NO_DATA_MATCHING_THE_FILTER" | translate }}
                </td>
                <td class="mat-cell" [attr.colspan]="userLastColumnConfig.length" *ngIf="!tableData.data.length && !refreshing">No {{ displayTableName }}</td>
                <td class="mat-cell" [attr.colspan]="userLastColumnConfig.length" *ngIf="!tableData.data.length && refreshing">
                    {{ "LOADING" | translate }}...
                </td>
                <td class="mat-cell" [attr.colspan]="userLastColumnConfig.length" *ngIf="tableData.data.length && refreshing">
                    {{ "LOADING" | translate }}...
                </td>
            </tr>
        </table>
    </div>
    <div class="paginator-container" [ngClass]="{ 'more-pages': infinitePages }" #paginatorContainer [tourAnchor]="showTour ? 'eleventhTableAnchor' : ''">
        <mat-paginator *ngIf="showPagination" [hidePageSize]="true" showFirstLastButtons (page)="pageSizeOrIndexChanged($event)"></mat-paginator>
        <!-- Custom items per page mat select -->
        <div class="mat-form-field-container mb-2" *ngIf="showPagination">
            <div class="mat-mdc-paginator-page-size-label">{{ "ROWS" | translate }}:</div>
            <mat-form-field
                appearance="outline"
                class="mat-form-field mat-paginator-page-size-select mat-primary mat-form-field-type-mat-select mat-form-field-appearance-legacy mat-form-field-can-float mat-form-field-hide-placeholder mat-form-field-should-float"
            >
                <mat-select (selectionChange)="pageSizeSelectedValueChange($event)" [(value)]="pageSizeSelectedValue">
                    <mat-option value="auto" *ngIf="autoRows">{{ "AUTO" | translate }}</mat-option>
                    <mat-option *ngFor="let val of itemsPerPageOptions; trackBy: trackByFn" [value]="val">
                        {{ val }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row-count" *ngIf="tableSelection.selected.length > 0 && showColumnsSelection && !isCollapseMode">
            {{ tableSelection.selected.length }}
            {{ tableSelection.selected.length > 1 ? ("ROWS_SELECTED" | translate | lowercase) : ("ROW_SELECTED" | translate | lowercase) }}
        </div>
    </div>
</app-table-material-wrapper>
