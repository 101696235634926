import { Component, OnInit, Input } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { SomeZixiObject } from "src/app/models/shared";
import { BroadcastersService } from "../../broadcasters/broadcasters.service";
import { Constants } from "./../../../constants/constants";

@Component({
    selector: "zx-broadcaster",
    template: `
        <div class="ellipsis d-inline" title="{{ model | errorMessage }}" *ngIf="model?.name">
            <span *ngIf="showTag" class="badge badge-primary me-1 tag" title="{{ 'BROADCASTER' | translate }}">B</span>
            <zx-status-full
                [icon]="showStatusIcon"
                [model]="model"
                [status]="model.generalStatus | statusClass"
                [standby]="model.is_backup && !model.active"
                [showStatusText]="showStatusText"
                [showOtherIcons]="showOtherIcons"
            ></zx-status-full>
            <fa-layers
                class="me-2"
                *ngIf="showStatusIcon"
                [ngbTooltip]="model?.is_backup ? 'Backup' : 'Primary'"
                triggers="hover click"
                container="body"
            >
                <fa-icon [icon]="'cloud'" size="md"></fa-icon>
                <fa-layers-text
                    *ngIf="model?.is_backup"
                    transform="shrink-7 right-2 down-1.5"
                    content="B"
                    style="color: var(--white);"
                    title="{{ 'BACKUP' | translate }}"
                ></fa-layers-text>
                <fa-layers-text
                    *ngIf="!model?.is_backup"
                    transform="shrink-7 right-2 down-1.5"
                    content="P"
                    style="color: var(--white);"
                    title="{{ 'PRIMARY' | translate }}"
                ></fa-layers-text>
            </fa-layers>
            <fa-icon
                icon="wrench"
                size="sm"
                class="me-1"
                *ngIf="model?.maintenance && showStatusIcon"
                title="{{ 'MAINTENANCE' | translate }}"
            ></fa-icon>
            <a
                *ngIf="showLink"
                [routerLink]="[
                    '/' + urls.clusters,
                    clusterId || model?.broadcaster_cluster_id | uId,
                    'broadcasters',
                    model.id | uId,
                    model.name
                ]"
                (click)="$event.stopPropagation()"
            >
                <ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight
                ><span *ngIf="showCluster"
                    ><span *ngIf="model.broadcaster_cluster?.name"
                        >&nbsp;@&nbsp;<ngb-highlight
                            title="{{ model.broadcaster_cluster?.name }}"
                            [result]="model.broadcaster_cluster?.name"
                            [term]="searchTerm"
                        ></ngb-highlight></span
                ></span>
            </a>
            <span *ngIf="!showLink"
                ><ngb-highlight title="{{ model.name }}" [result]="model.name" [term]="searchTerm"></ngb-highlight
                ><span *ngIf="showCluster"
                    ><span *ngIf="model.broadcaster_cluster?.name"
                        >&nbsp;@&nbsp;<ngb-highlight
                            title="{{ model.broadcaster_cluster?.name }}"
                            [result]="model.broadcaster_cluster?.name"
                            [term]="searchTerm"
                        ></ngb-highlight></span></span
            ></span>
        </div>
        <span *ngIf="!model">-</span>
    `
})
export class ZxBroadcasterComponent implements OnInit {
    @Input() model?: SomeZixiObject;
    @Input() showLink?: boolean;
    @Input() showTag?: boolean;
    @Input() showCluster?: boolean;
    @Input() showStatusText?: boolean = false;
    @Input() showStatusIcon?: boolean = true;
    @Input() showOtherIcons?: boolean = true;
    @Input() broadcasterId?: number;
    @Input() clusterId?: number;
    @Input() searchTerm?: string | string[];

    urls = Constants.urls;

    constructor(private bs: BroadcastersService) {}

    async ngOnInit() {
        if (!this.model && !this.broadcasterId) return;
        if (!this.model) {
            this.model = this.bs.getCachedBroadcaster(this.broadcasterId) as unknown as SomeZixiObject;
            if (!this.model)
                this.model = (await firstValueFrom(
                    this.bs.refreshBroadcaster(this.broadcasterId)
                )) as unknown as SomeZixiObject;
        }
        if (this.model.readOnly) this.showLink = false;
    }
}
