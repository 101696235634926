<!-- Bulk Actions: Desktop -->
<div
    [ngClass]="{ 'd-flex-imp': buttonCount === 1, 'd-md-flex': buttonCount === 2, 'd-lg-flex': buttonCount === 3 }"
    class="form-group d-none d-xxl-flex grid-gap-2 mb-0"
>
    <button
        *ngIf="switchChannelButton"
        type="button"
        class="btn btn-outline-secondary"
        (click)="clickSwitchChannel()"
        [disabled]="!canSwitchChannel"
        title="{{ 'SWITCH_CHANNEL' | translate }}"
    >
        <fa-icon icon="random" size="sm"></fa-icon>
        <span class="d-none d-lg-inline-block ms-1">{{ "CHANNEL" | translate }}</span>
    </button>
    <button *ngIf="resetButton" type="button" class="btn btn-outline-secondary" (click)="clickMultiReset()" title="{{ 'RESET_STATS' | translate }}">
        <fa-icon icon="arrow-rotate-left" size="sm"></fa-icon>
        <span class="d-none d-lg-inline-block ms-1">{{ "RESET" | translate }}</span>
    </button>
    <button *ngIf="toggleMuteButton" type="button" class="btn btn-outline-secondary" (click)="clickMultiToggleMute(true)" title="{{ 'MUTE_ALL' | translate }}">
        <fa-icon icon="bell-slash" size="sm"></fa-icon>
        <span class="d-none d-lg-inline-block ms-1">{{ "MUTE_ALL" | translate }}</span>
    </button>
    <button
        *ngIf="toggleMuteButton"
        type="button"
        class="btn btn-outline-secondary"
        (click)="clickMultiToggleMute(false)"
        title="{{ 'UNMUTE_ALL' | translate }}"
    >
        <fa-icon icon="bell" size="sm"></fa-icon>
        <span class="d-none d-lg-inline-block ms-1">{{ "UNMUTE_ALL" | translate }}</span>
    </button>
    <button *ngIf="editButton" type="button" class="btn btn-outline-primary" (click)="clickMultiEdit()" title="{{ 'EDIT_ALL' | translate }}">
        <fa-icon icon="pencil" size="sm"></fa-icon>
        <span class="d-none d-lg-inline-block ms-1">{{ "EDIT_ALL" | translate }}</span>
    </button>
    <button *ngIf="enableButton" type="button" class="btn btn-outline-success" (click)="clickMultiEnable()" title="{{ 'ENABLE' | translate }}">
        <fa-icon icon="power-off" size="sm"></fa-icon>
        <span class="d-none d-lg-inline-block ms-1">{{ "ENABLE" | translate }}</span>
    </button>
    <button *ngIf="toggleStateButton" type="button" class="btn btn-outline-warning" (click)="clickMultiToggleState(false)" title="{{ 'DISABLE' | translate }}">
        <fa-icon icon="ban" size="sm"></fa-icon>
        <span class="d-none d-lg-inline-block ms-1">{{ "DISABLE" | translate }}</span>
    </button>
    <button *ngIf="deleteButton" type="button" class="btn btn-outline-danger" (click)="clickMultiDelete()" title="{{ 'DELETE_ALL' | translate }}">
        <fa-icon icon="trash-alt" size="sm"></fa-icon>
        <span class="d-none d-lg-inline-block ms-1">{{ "DELETE_ALL" | translate }}</span>
    </button>
</div>

<!-- Bulk Actions: Mobile -->
<div class="form-group d-flex d-xxl-none mb-0" [ngClass]="{ 'd-none': buttonCount === 1, 'd-md-none': buttonCount === 2, 'd-lg-none': buttonCount === 3 }">
    <div>
        <button
            class="btn btn-outline-secondary"
            id="actionMultiDropdown"
            [matMenuTriggerFor]="mobileMenu"
            title="{{ 'ACTIONS' | translate }}"
            (click)="$event.stopPropagation()"
        >
            <fa-icon icon="ellipsis-v" size="md" class="d-inline-block d-lg-none"></fa-icon
            ><span class="d-none d-lg-inline-block">{{ "ACTIONS" | translate }}</span
            ><fa-icon icon="caret-down" size="sm" class="ms-1 d-none d-lg-inline-block"></fa-icon>
        </button>
        <div aria-labelledby="actionMultiDropdown" class="shadow-sm">
            <mat-menu #mobileMenu="matMenu" yPosition="below">
                <button
                    *ngIf="switchChannelButton"
                    class="text-secondary"
                    (click)="clickSwitchChannel()"
                    [disabled]="!canSwitchChannel"
                    title="{{ 'SWITCH_CHANNEL' | translate }}"
                    mat-menu-item
                >
                    <fa-icon icon="random" size="sm" [fixedWidth]="true"></fa-icon>
                    {{ "SWITCH_CHANNEL" | translate }}
                </button>
                <button *ngIf="resetButton" (click)="clickMultiReset()" class="text-secondary" title="{{ 'RESET_STATS' | translate }}" mat-menu-item>
                    <fa-icon icon="arrow-rotate-left" size="sm" [fixedWidth]="true"></fa-icon>
                    {{ "RESET_ALL" | translate }}
                </button>
                <button *ngIf="toggleMuteButton" (click)="clickMultiToggleMute(true)" class="text-secondary" title="{{ 'MUTE_ALL' | translate }}" mat-menu-item>
                    <fa-icon icon="bell-slash" size="sm" [fixedWidth]="true"></fa-icon>
                    {{ "MUTE_ALL" | translate }}
                </button>
                <button
                    *ngIf="toggleMuteButton"
                    (click)="clickMultiToggleMute(false)"
                    class="text-secondary"
                    title="{{ 'UNMUTE_ALL' | translate }}"
                    mat-menu-item
                >
                    <fa-icon icon="bell" size="sm" [fixedWidth]="true"></fa-icon>
                    {{ "UNMUTE_ALL" | translate }}
                </button>
                <button *ngIf="enableButton" (click)="clickMultiEnable()" class="text-success" title="{{ 'ENABLE' | translate }}" mat-menu-item>
                    <fa-icon icon="power-off" size="sm" [fixedWidth]="true"></fa-icon>
                    {{ "ENABLE" | translate }}
                </button>
                <button *ngIf="toggleStateButton" (click)="clickMultiToggleState(false)" class="text-warning" title="{{ 'DISABLE' | translate }}" mat-menu-item>
                    <fa-icon icon="ban" size="sm" [fixedWidth]="true"></fa-icon>
                    {{ "DISABLE" | translate }}
                </button>
                <button *ngIf="editButton" class="text-primary" (click)="clickMultiEdit()" title="{{ 'EDIT_ALL' | translate }}" mat-menu-item>
                    <fa-icon icon="pencil" size="sm" [fixedWidth]="true"></fa-icon>
                    {{ "EDIT_ALL" | translate }}
                </button>
                <button *ngIf="deleteButton" (click)="clickMultiDelete()" class="text-danger" title="{{ 'DELETE_ALL' | translate }}" mat-menu-item>
                    <fa-icon icon="trash-alt" size="sm" [fixedWidth]="true"></fa-icon>
                    {{ "DELETE_ALL" | translate }}
                </button>
            </mat-menu>
        </div>
    </div>
</div>
