import { NgModule } from "@angular/core";

// Font Awesome
import { FontAwesomeModule, FaIconLibrary } from "@fortawesome/angular-fontawesome";

// Regular Icons
import {
    faBadgeCheck,
    faClone,
    faCheckSquare,
    faStickyNote,
    faBullseyeArrow
} from "@fortawesome/pro-regular-svg-icons";
import { faClipboard as faClipboardRegular } from "@fortawesome/pro-regular-svg-icons";
import { faLock as faLockRegular } from "@fortawesome/pro-regular-svg-icons";
import { faUnlock as faUnlockRegular } from "@fortawesome/pro-regular-svg-icons";
import { faKey as faKeyRegular } from "@fortawesome/pro-regular-svg-icons";
import { faMinusCircle as faMinusCircleRegular } from "@fortawesome/pro-regular-svg-icons";
import { faPlusCircle as faPlusCircleRegular } from "@fortawesome/pro-regular-svg-icons";
import { faSquare as faSquareRegular } from "@fortawesome/pro-regular-svg-icons";
import { faFileImport as faFileImportRegular } from "@fortawesome/pro-regular-svg-icons";
import { faDroplet as faDropletRegular } from "@fortawesome/pro-regular-svg-icons";
import { faFilterSlash as faFilterSlashRegular } from "@fortawesome/pro-regular-svg-icons";

// Solid Icons
import {
    // Nav Icons
    faTachometer,
    faTh,
    faRss,
    faRssSquare, // Feeder?
    faSatelliteDish, // Feeder?
    faCloud,
    faCloudUploadAlt,
    faCloudDownloadAlt,
    faVideo,
    faCogs,
    faNetworkWired, // Channels?
    faProjectDiagram, // Channels?
    faShare,
    faBullseye, // Targets?
    faMagic,
    faExclamationTriangle, // Events?
    faUsers,
    faUsersCog, // Users?
    faSlidersH, // Settings?
    faSignInAlt,
    faSignOutAlt,
    faAward, // Licenses?
    faChartBar,
    faAddressCard, // Accounts?
    faDatabase,
    faTools,
    faPlay,
    faPause,
    faPauseCircle,
    faPlayCircle,
    faSearch,
    faLongArrowAltRight,
    // Status Icons
    faCircle,
    faCheckCircle,
    faMinusCircle,
    faExclamationCircle,
    faBell,
    faBellSlash,
    faBan,
    // Other Icons
    faInfoCircle,
    faAngleDown,
    faAngleUp,
    faWindowClose,
    faTimes,
    faTimesCircle,
    faChevronCircleLeft,
    faChevronUp,
    faChevronDown,
    faBars,
    faSortDown,
    faPlus,
    faMinus,
    faPlusCircle,
    faPlusSquare,
    faMinusSquare,
    faSyncAlt,
    faPencil,
    faPencilAlt,
    faEnvelope,
    faTrashAlt,
    faCheck,
    faPowerOff,
    faAsterisk,
    faEye,
    faEyeSlash,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faClock,
    faDownload,
    faDotCircle,
    faEllipsisH,
    faEllipsisV,
    faGripLinesVertical,
    faGripLines,
    faSortAmountDown,
    faSortAmountUp,
    faList,
    faThList,
    faThLarge,
    faFolder,
    faFileVideo,
    faLevelUpAlt,
    faStar,
    faUser,
    faUserCircle,
    faExternalLink,
    faExternalLinkSquareAlt,
    faExternalLinkAlt,
    faServer,
    faExpand,
    faExpandAlt,
    faCompressArrowsAlt,
    faCompressAlt,
    faCompress,
    faArrowsUpDownLeftRight,
    faAlignJustify,
    faChartLine,
    faQuestionCircle,
    faWrench,
    faChevronLeft,
    faChevronRight,
    faUnlock,
    faCalendar,
    faCalendarAlt,
    faTasks,
    faListOl,
    faUnlink,
    faLink,
    faStream,
    faSitemap,
    faCog,
    faAngleDoubleUp,
    faAngleDoubleDown,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faRandom,
    faHistory,
    faChartNetwork,
    faUserSecret,
    faFileDownload,
    faQuestion,
    faCopy,
    faClipboard,
    faClipboardList,
    faUndo,
    faRedo,
    faMap,
    faMapMarked,
    faMapMarkedAlt,
    faMarker,
    faMapMarkerAlt,
    faGlobe,
    faGlobeAmericas,
    faMapMarkerEdit,
    faMapMarkerCheck,
    faMapSigns,
    faSlash,
    faLayerGroup,
    faObjectGroup,
    faObjectUngroup,
    faColumns,
    faFileExcel,
    faRoute,
    faFileImport,
    faChevronCircleUp,
    faChevronCircleDown,
    faGripVertical,
    faGripHorizontal,
    faArrowAltFromLeft,
    faArrowAltFromTop,
    faChartArea,
    faHeartbeat,
    faTable,
    faFileExclamation,
    faDroplet,
    faMapPin,
    faPrint,
    faChartScatter,
    faMoon,
    faSun,
    faScroll,
    faArrowUpRightFromSquare,
    faStop,
    faLeftFromLine,
    faRightFromLine,
    faForwardStep,
    faClapperboard,
    faMonitorHeartRate,
    faHeartRate,
    faStepBackward,
    faStepForward,
    faWavePulse,
    faCircleQuestion,
    faArrowsRotate,
    faGrid,
    faGrid2,
    faSquare,
    faThumbTack,
    faTag,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faMaximize,
    faExpandArrows,
    faCompressArrows,
    faCircleNodes,
    faClockRotateLeft,
    faArrowFromRight,
    faArrowToRight,
    faArrowFromLeft,
    faArrowToLeft,
    faFilm,
    faFilmSimple,
    faFilter,
    faFilterSlash,
    faDisplay,
    faBroomWide,
    faTrashUndo,
    faSquarePen,
    faPenSquare,
    faClapperboardPlay,
    faSplit
} from "@fortawesome/pro-solid-svg-icons";

import { faClipboard as faClipboardSolid } from "@fortawesome/pro-solid-svg-icons";
import { faLock as faLockSolid } from "@fortawesome/pro-solid-svg-icons";
import { faKey as faKeySolid } from "@fortawesome/pro-solid-svg-icons";

@NgModule({
    declarations: [],
    exports: [FontAwesomeModule]
})
export class FontAwesome {
    constructor(library: FaIconLibrary) {
        // Add an icon to the library for convenient access in other components
        library.addIcons(
            // Solid
            faClone,
            faTachometer,
            faTh,
            faRss,
            faRssSquare,
            faSatelliteDish,
            faCloud,
            faCloudUploadAlt,
            faCloudDownloadAlt,
            faVideo,
            faCogs,
            faNetworkWired,
            faProjectDiagram,
            faShare,
            faBullseye,
            faMagic,
            faExclamationCircle,
            faExclamationTriangle,
            faUsers,
            faSearch,
            faUsersCog,
            faSlidersH,
            faSignInAlt,
            faSignOutAlt,
            faAward,
            faChartBar,
            faAddressCard,
            faDatabase,
            faInfoCircle,
            faAngleDown,
            faAngleUp,
            faWindowClose,
            faTimes,
            faTimesCircle,
            faBadgeCheck,
            faChevronCircleLeft,
            faChevronUp,
            faChevronDown,
            faBars,
            faSortDown,
            faPlusCircle,
            faPlusSquare,
            faMinusSquare,
            faPlus,
            faMinus,
            faSyncAlt,
            faPencil,
            faPencilAlt,
            faEnvelope,
            faTrashAlt,
            faCheck,
            faPowerOff,
            faAsterisk,
            faLongArrowAltRight,
            faEye,
            faEyeSlash,
            faMinusCircle,
            faCheckCircle,
            faCircle,
            faTools,
            faCaretDown,
            faCaretLeft,
            faCaretRight,
            faCaretUp,
            faBell,
            faBellSlash,
            faBan,
            faClock,
            faDownload,
            faDotCircle,
            faEllipsisH,
            faEllipsisV,
            faGripLinesVertical,
            faGripLines,
            faSortAmountDown,
            faSortAmountUp,
            faList,
            faThList,
            faThLarge,
            faFolder,
            faFileVideo,
            faLevelUpAlt,
            faStar,
            faUser,
            faUserCircle,
            faExternalLink,
            faExternalLinkSquareAlt,
            faExternalLinkAlt,
            faServer,
            faPlay,
            faPause,
            faPauseCircle,
            faPlayCircle,
            faExpand,
            faExpandAlt,
            faCompressArrowsAlt,
            faCompressAlt,
            faCompress,
            faArrowsUpDownLeftRight,
            faAlignJustify,
            faChartLine,
            faQuestionCircle,
            faWrench,
            faChevronLeft,
            faChevronRight,
            faUnlock,
            faCalendar,
            faCalendarAlt,
            faTasks,
            faListOl,
            faUnlink,
            faLink,
            faStream,
            faSitemap,
            faCog,
            faAngleDoubleUp,
            faAngleDoubleDown,
            faAngleDoubleLeft,
            faAngleDoubleRight,
            faRandom,
            faHistory,
            faChartNetwork,
            faUserSecret,
            faFileDownload,
            faQuestion,
            faCopy,
            faClipboard,
            faClipboardList,
            faUndo,
            faRedo,
            faBullseyeArrow,
            faMap,
            faMapMarked,
            faMapMarkedAlt,
            faMarker,
            faMapMarkerAlt,
            faGlobe,
            faGlobeAmericas,
            faMapMarkerEdit,
            faMapMarkerCheck,
            faMapSigns,
            faSquareRegular,
            faCheckSquare,
            faStickyNote,
            faSlash,
            faLayerGroup,
            faObjectGroup,
            faObjectUngroup,
            faColumns,
            faClipboardRegular,
            faClipboardSolid,
            faLockRegular,
            faUnlockRegular,
            faLockSolid,
            faKeyRegular,
            faKeySolid,
            faMinusCircleRegular,
            faPlusCircleRegular,
            faSquareRegular,
            faFileImportRegular,
            faFileExcel,
            faRoute,
            faFileImport,
            faChevronCircleUp,
            faChevronCircleDown,
            faGripVertical,
            faGripHorizontal,
            faArrowAltFromLeft,
            faArrowAltFromTop,
            faChartArea,
            faHeartbeat,
            faTable,
            faFileExclamation,
            faDroplet,
            faDropletRegular,
            faFilterSlashRegular,
            faMapPin,
            faPrint,
            faChartArea,
            faChartScatter,
            faMoon,
            faSun,
            faScroll,
            faArrowUpRightFromSquare,
            faStop,
            faLeftFromLine,
            faRightFromLine,
            faForwardStep,
            faClapperboard,
            faMonitorHeartRate,
            faHeartRate,
            faStepBackward,
            faStepForward,
            faWavePulse,
            faCircleQuestion,
            faArrowsRotate,
            faGrid,
            faGrid2,
            faThumbTack,
            faSquare,
            faTag,
            faChevronDoubleLeft,
            faChevronDoubleRight,
            faMaximize,
            faExpandArrows,
            faCompressArrows,
            faCircleNodes,
            faClockRotateLeft,
            faArrowFromRight,
            faArrowToRight,
            faArrowFromLeft,
            faArrowToLeft,
            faFilm,
            faFilmSimple,
            faFilter,
            faFilterSlash,
            faDisplay,
            faBroomWide,
            faTrashUndo,
            faSquarePen,
            faPenSquare,
            faClapperboardPlay,
            faSplit
        );
    }
}
