<ng-container>
    <zx-adaptive [model]="target.target.adaptiveChannel" [showLink]="true" [searchTerm]="searchTerm" *ngIf="target.adaptive"></zx-adaptive>
    <zx-delivery
        [model]="target.target.deliveryChannel"
        [showLink]="true"
        [searchTerm]="searchTerm"
        *ngIf="target.target.mediaconnect_flow_id == null && target.delivery"
    ></zx-delivery>
    <zx-mediaconnect-flow
        [model]="target.target.mediaconnectFlow"
        [showLink]="true"
        [searchTerm]="searchTerm"
        *ngIf="target.target.mediaconnect_flow_id != null"
    ></zx-mediaconnect-flow>
    <zx-medialive-channel
        [model]="target.target.mediaLiveChannel"
        [showLink]="true"
        [searchTerm]="searchTerm"
        *ngIf="target.target.medialive_channel_id != null"
    ></zx-medialive-channel>
    <span *ngIf="!target.target.medialive_channel_id && !target.target.mediaconnect_flow_id && !target.target.deliveryChannel && !target.target.adaptiveChannel"
        >-</span
    >
</ng-container>
